// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function sleep(seconds) {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function escapeRegExp(str) {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function hashCode(str) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function checksum(input) {
  if (!input || isEmptyObject(input)) return;
  else if (isPlainObject(input)) input = JSON.stringify(input);

  var hash = 0;
  if (input.length == 0) return hash;
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function defined(value) {
  return value !== undefined;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function rgbToHex(rgb) {
  rgb = rgb.replace(/[^0-9,]/g, '').split(',');
  var r = parseInt(rgb[0]);
  var g = parseInt(rgb[1]);
  var b = parseInt(rgb[2]);
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
